<template>
    <div class="flex flex-1 flex-col items-center">
        <ConfirmationModal codeConfirmation @success="actionSuccess" />

        <ModalAddAddress
            :visible="modalAddAddressVisible"
            @refresh="actionSuccess"
            @close="modalAddAddressVisible = false"
        />

        <div v-loading="$wait.is('loading')" element-loading-spinner="el-custom-spinner" class="card w-10/12" style="max-width: 800px">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
                <h1>{{ $t('clients.address_details') }}</h1>
                <div>
                    <el-button v-show="addressData.canDelete > 1" type="primary" @click="goToAllAddresses">
                        {{ $t('common.go_back') }}
                    </el-button>
                    <el-button type="danger" :disabled="addressData.canDelete === 1" @click="deleteAddress">
                        {{ $t('common.delete') }}
                    </el-button>
                    <el-button v-if="addressData.canDelete === 1" type="primary" @click="showModal">
                        {{ $t('clients.add_address') }}
                    </el-button>
                </div>
            </header>

            <div class="px-16">
                <el-form ref="form" label-position="top" :model="addressData" :rules="rules">
                    <el-form-item :label="$t('common.google_map_search')">
                        <AutoComplete @placeFound="placeFound" />
                    </el-form-item>
                    <div class="grid grid-cols-2 gap-8">
                        <el-form-item :label="$t('clients.title')">
                            <el-input v-model="addressData.title" />
                        </el-form-item>
                        <el-form-item v-if="userIsBuildingCompany()" :label="$t('clients.status')">
                            <el-select v-model="addressData.status" class="w-full" clearable>
                                <el-option value="ongoing" :label="$t('clients.ongoing')" />
                                <el-option value="completed" :label="$t('clients.completed')" />
                                <el-option value="not_finished" :label="$t('clients.not_finished')" />
                                <el-option value="future_work" :label="$t('clients.future_work')" />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 gap-8">
                        <el-form-item label="CO">
                            <el-input v-model="addressData.co" />
                        </el-form-item>
                        <div class="flex">
                            <el-form-item class="w-full" :label="$t('clients.street')" prop="street">
                                <el-input v-model="addressData.street" />
                            </el-form-item>
                            <el-form-item class="ml-2" style="width: 55px;" :label="$t('clients.floor')" prop="floor">
                                <el-input v-model="addressData.floor" />
                            </el-form-item>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-8">
                        <el-form-item :label="$t('clients.city')" prop="city">
                            <el-input v-model="addressData.city" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.post_code')" prop="post_code">
                            <el-input v-model="addressData.post_code" />
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 gap-8">
                        <el-form-item :label="$t('clients.telephone')">
                            <el-input v-model="addressData.telephone" />
                        </el-form-item>
                        <el-form-item :label="$t('clients.door_code')">
                            <el-input v-model="addressData.door_code" />
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 gap-8">
                        <el-form-item :label="$t('clients.apartment_type')">
                            <el-select v-model="addressData.apartment_type" class="w-full">
                                <el-option value="house" :label="$t('clients.house')" />
                                <el-option value="flat" :label="$t('clients.flat')" />
                                <el-option value="two_level_flat" :label="$t('clients.two_level_flat')" />
                                <el-option value="construction_area" :label="$t('clients.construction_area')" />
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="$t('clients.square_meters')">
                            <el-input v-model="addressData.square_meters" />
                        </el-form-item>
                    </div>
                    <div class="grid grid-cols-2 gap-8">
                        <div>
                            <el-form-item :label="$t('clients.alarm_unblock_code')">
                                <el-input v-model="addressData.unblock_alarm_code" />
                            </el-form-item>
                            <el-form-item :label="$t('clients.alarm_block_code')">
                                <el-input v-model="addressData.block_alarm_code" />
                            </el-form-item>
                        </div>
                        <el-form-item :label="$t('clients.alarm_instructions')">
                            <el-input v-model="addressData.alarm_code_instructions" type="textarea" resize="none" rows="5" />
                        </el-form-item>
                    </div>
                    <el-form-item :label="$t('clients.check_in_out_radius') + addressData.geofence_radius + 'm'">
                        <el-slider v-model="addressData.geofence_radius" :min="50" :max="500" :step="10" />
                    </el-form-item>
                </el-form>

                <GoogleMap
                    :coords="{
                        map_lat: addressData.map_lat,
                        map_lng: addressData.map_lng,
                    }"
                    :geofenceRadius="addressData.geofence_radius"
                    :markerDraggable="true"
                    @markerDragged="markerMapDragged"
                />
            </div>

            <div class="flex justify-end pt-3 mt-10 border-t border-gray-200">
                <el-button :loading="$wait.is('updating')" type="success" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Api from './addresses.api';

export default {
    components: {
        AutoComplete:    () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
        GoogleMap:       () => import(/* webpackChunkName: "GoogleMapForAddress" */ '@/components/GoogleMapForAddress/GoogleMapForAddress.vue'),
        ModalAddAddress: () => import(/* webpackChunkName: "ClientAddresses/AddressesModalAdd" */ './AddressesModalAdd'),
    },

    data() {
        return {
            addressUuid:            this.$route.params.address_uuid,
            clientUuid:             this.$route.params.uuid,
            modalAddAddressVisible: false,
            addressData:            {
                map_lat:   59.3260664,
                map_lng:   17.8471233,
                canDelete: false,
            },
            rules: {
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.$wait.start('loading');
            try {
                this.addressData = await Api.getDetails(this.clientUuid, this.addressUuid);
            } finally {
                this.$wait.end('loading');
                this.$wait.end('loading.initial_address');
            }
        },

        async update() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('updating');

            try {
                await Api.update(this.addressUuid, this.addressData);
                this.$notify.success({ title: 'Success' });
            } finally {
                this.$wait.end('updating');
            }
        },

        deleteAddress() {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(this.addressData.uuid),
                actionType: 'destroy',
            });
        },

        placeFound(data) {
            this.addressData.post_code = data.postcode;
            this.addressData.city = data.city;
            this.addressData.street = data.street;
            this.addressData.map_lat = data.map_lat;
            this.addressData.map_lng = data.map_lng;
        },

        goToAllAddresses() {
            this.$router.push({ name: 'client_details_addresses', params: { uuid: this.clientUuid } });
        },

        showModal() {
            this.modalAddAddressVisible = true;
        },

        markerMapDragged({ mapLat, mapLng }) {
            this.addressData.map_lat = mapLat;
            this.addressData.map_lng = mapLng;
        },

        actionSuccess() {
            this.goToAllAddresses();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
